import { PremiumVideos } from "@/components/premiumVideos/PremiumVideos";
import React from "react";

export const PremiumDigitalPage: React.FC = () => {
  return (
    <div>
      <PremiumVideos />
    </div>
  );
};
