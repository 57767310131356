import {
  CaretRightOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TikTokOutlined,
} from "@ant-design/icons";
import { Space } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { CheckMarkIcon } from "@/components/leylineIcons/svgs";
import Poster1 from "/images/thumbnails/1.png";
import Poster2 from "/images/thumbnails/2.png";
import Poster3 from "/images/thumbnails/3.png";
import Poster4 from "/images/thumbnails/4.png";
import Poster5 from "/images/thumbnails/5.png";
import Poster6 from "/images/thumbnails/6.png";
import Poster7 from "/images/thumbnails/7.png";
import Poster8 from "/images/thumbnails/8.png";

export const PremiumVideos: React.FC = () => {
  const [playingVideo, setPlayingVideo] = useState<number | null>(null);

  const videoList = [
    {
      id: 0,
      video: "https://www.youtube.com/watch?v=Xr32yzCkAwE",
      poster: Poster2,
    },
    {
      id: 1,
      video: "https://www.youtube.com/watch?v=zQh6gFu35bg",
      poster: Poster3,
    },
    {
      id: 2,
      video: "https://www.youtube.com/watch?v=mAWIQVoMk98",
      poster: Poster5,
    },
    {
      id: 3,
      video: "https://www.youtube.com/watch?v=5xuh7Td85xA",
      poster: Poster7,
    },
    {
      id: 4,
      video: "https://www.youtube.com/watch?v=RUDus75wlhs",
      poster: Poster6,
    },
    {
      id: 5,
      video: "https://www.youtube.com/watch?v=Gy_fu8SqR7s",
      poster: Poster8,
    },

    {
      id: 6,
      video: "https://www.youtube.com/shorts/v_fnhfi_cBI",
      poster: Poster1,
    },
    {
      id: 7,
      video: "https://www.youtube.com/shorts/zrRqvZJ_c_Y",
      poster: Poster4,
    },
  ];

  return (
    <section className="py-16">
      <div className="container max-w-7xl mx-auto md:px-14 px-4 bg-white py-16">
        <div className="">
          <h2 className="md:text-5xl text-3xl md:leading-snug font-semibold text-black mb-2">
            Premium Digital Videos
            <span className="text-xl ml-5">From 50 USD / Minute</span>
          </h2>
          <p className="md:flex items-center">
            <CheckMarkIcon />
            Digital Ads/Social Media Content/TVC,
            <span className="font-bold text-xl px-1"> 90% </span> cheaper and
            <span className="font-bold text-xl px-1"> 10x </span> faster than
            your traditional workflow
          </p>
          <h4 className="md:text-5xl text-3xl md:leading-snug font-semibold text-black mt-5">
            Full Social Media Account Management
            <span className="text-xl ml-5">From 500 USD / month</span>
          </h4>
          <div className="md:flex">
            <ul className="flex md:w-[40%] w-full items-center gap-4 justify-center">
              <li>
                <TikTokOutlined className="text-5xl" />
              </li>
              <li>
                <LinkedinOutlined className="text-5xl" />
              </li>
              <li>
                <InstagramOutlined className="text-5xl" />
              </li>
            </ul>
            <ul className="flex md:w-[60%]  w-full  gap-4 flex-col">
              <li className="flex items-center">
                <CheckMarkIcon />4 Video postings on LinkedIn / Instagram /
                TikTok
              </li>
              <li className="flex items-center">
                <CheckMarkIcon />
                Data-Tracking and full operations management
              </li>
            </ul>
          </div>
          <div className="border-t-2 border-dotted my-8" />
          <ul className="mt-6 flex items-center md:flex-row flex-col gap-6 justify-center">
            <li className="flex items-center">
              <CheckMarkIcon />
              Affordable
            </li>
            <li className="flex items-center">
              <CheckMarkIcon />
              Hassle Free
            </li>
            <li className="flex items-center">
              <CheckMarkIcon />
              High Performing
            </li>
          </ul>
          <p className="text-xl text-center">Marketing services that</p>
          <p className="text-xl text-center">Actually drives ROI</p>
          <Link
            to="https://calendly.com/yinhan-leylinepro/30min"
            target="_blank"
            className="bg-[#00ADAE] py-2 px-4 text-xl font-semibold !text-white rounded-md transition-all duration-500 hover:opacity-80 border-none mx-auto flex w-fit"
          >
            Contact Sales
          </Link>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 gap-5 md:mt-16 mt-10">
          {videoList.map((video) => {
            const youtubeThumbnail = video.poster;
            const videoId = video.video.match(
              /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/|youtube\.com\/shorts\/)([a-zA-Z0-9_-]{11})/,
            )?.[1];

            return (
              <div
                key={video.id}
                className="relative w-full aspect-video h-auto overflow-hidden transition-all duration-500 group"
              >
                {playingVideo === video.id ? (
                  <iframe
                    src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                    title={`YouTube video ${video.id}`}
                    className="absolute inset-0 w-full h-full"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                ) : (
                  <div>
                    <button
                      className="border-none"
                      onClick={() => setPlayingVideo(video.id)}
                      onKeyUp={() => {}}
                      onKeyDown={() => {}}
                    >
                      <img
                        src={youtubeThumbnail || ""}
                        alt={`YouTube Thumbnail for video ${video.id}`}
                        className="object-cover w-full h-full cursor-pointer"
                      />
                    </button>
                    <Space
                      className="absolute z-10 top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 flex items-center justify-center w-12 h-12 bg-[#00ADAE] rounded-full cursor-pointer group-hover:opacity-100 opacity-0"
                      onClick={() => setPlayingVideo(video.id)}
                    >
                      <CaretRightOutlined className="w-full h-full text-white text-2xl" />
                    </Space>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="mt-10 text-center">
          <Link
            to="https://m3d60c6dc7z.larksuite.com/docx/IobydOSNnol5WqxwvkRu50I0slb"
            target="_blank"
            className="bg-[#00ADAE] py-3 px-6 md:text-2xl text-xl font-semibold !text-white rounded-md transition-all duration-500 hover:opacity-80 border-none "
          >
            See more Videos
          </Link>
        </div>
      </div>
    </section>
  );
};
